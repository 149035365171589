import React from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import OutboundLink from './OutboundLink';

const EXTERNAL_LINK_REL = ['nofollow', 'noopener', 'noreferrer'];

/**
 * General `Link`
 *
 * All internal links (starting with `/` or matching https://*.hausgold.de`)
 * will be handled by `GatsbyLink` while all others will be treated as external
 * and handled by Google Analytic's `OutboundLink`.
 */
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  eventLabel,
  eventAction,
  eventCategory,
  ...other
}) => {
  // Get all "Follow links" from Contentful
  const {
    allContentfulFollowLink: { nodes: followLinks },
  } = useStaticQuery(graphql`
    {
      allContentfulFollowLink {
        nodes {
          url
        }
      }
    }
  `);

  /*
   * Tailor the following test to your environment.
   * This example assumes that any internal link (intended for Gatsby)
   * will start with exactly one slash, and that anything else is external.
   */
  const internal = /(^\/(?!\/))|(^(https*:\/\/(.*)\.hausgold.de))/.test(to);
  const replacePath = (_path) =>
    _path === `/` ? _path : _path.replace(/\/$|$/, `/`);

  // Use Gatsby Link for internal links, and <a> with nofollow for others
  if (internal || to === undefined) {
    return (
      <GatsbyLink
        to={replacePath(to.replace(/^https*:\/\/(www.)*hausgold\.de\//, '/'))}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }

  /*
   * If the `to` link is included in the Follow Links, `nofollow` should NOT
   * be used. Otherwise, `nofollow` is applied.
   */
  const isIncludedInFollowList = followLinks
    .map((link) => link.url)
    .includes(to);

  return (
    <OutboundLink
      rel={
        isIncludedInFollowList
          ? EXTERNAL_LINK_REL.slice(
              EXTERNAL_LINK_REL.indexOf('nofollow') + 1,
              EXTERNAL_LINK_REL.length
            )
          : EXTERNAL_LINK_REL
      }
      eventLabel={eventLabel}
      eventAction={eventAction}
      eventCategory={eventCategory}
      href={to}
      {...other}
    >
      {children}
    </OutboundLink>
  );
};

export default Link;
