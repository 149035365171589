import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import ContentBlock from '../components/ContentBlock';
import OutboundLink from '../components/OutboundLink';

const Career = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const site = get(data, 'site');
  const jobs = get(data, 'allJobOffer.edges');
  const topCompanyBadge = get(data, 'topCompanyBadge');
  const openCompanyBadge = get(data, 'openCompanyBadge');
  const heroImage = get(page, 'heroImage');
  const intro = page.contentBlocks.filter(
    (block) => block.title === 'Intro'
  )[0];
  const contact = page.contentBlocks.filter(
    (block) => block.title === 'Kontakt'
  )[0];
  const benefits = page.contentBlocks.filter(
    (block) => block.title === 'Das bieten wir Dir:'
  )[0];
  const values = page.contentBlocks.filter(
    (block) => block.title === 'Das ist uns wichtig'
  )[0];

  const translations = {
    'full-time': 'Vollzeit',
    'part-time': 'Teilzeit',
    permanent: 'Festanstellung',
    intern: 'Praktikum',
    trainee: 'Trainee Stelle',
    freelance: 'Freelance Position',
  };

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
    {
      title: page.title,
      url: `${site.siteMetadata.siteUrl}/${page.slug}/`,
    },
  ];

  return (
    <Layout
      location={location}
      cookieConsent
      header={
        <Header
          heroImage={heroImage}
          title={page.headline}
          subTitle={page.subTitle}
        />
      }
    >
      <SEO
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type={page.type}
        breadcrumbs={breadcrumbs}
        description={page.metaDescription}
        indexable={page.indexable}
      />

      <Container id="intro" className="mb-5 mt-6">
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <a className="nav-link active" href="#intro">
              Intro
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#open-positions">
              Offene Positionen
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#benefits">
              Das bieten wir
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#values">
              Das ist uns wichtig
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">
              Kontakt
            </a>
          </li>
        </ul>
      </Container>
      <ContentBlock block={intro} />

      <div id="open-positions" className="swoosh swoosh-blue swoosh-right" />
      <div className="bg-primary py-3 text-white">
        <Container className="my-5">
          <Row>
            <Col>
              <h2>Offene Positionen</h2>
              {(!jobs || jobs.length === 0) && (
                <p className="w-100 text-center text-white">
                  Derzeit keine offenen Stellen
                </p>
              )}
              {jobs?.map(({ node: job }) => (
                <div
                  key={job.id}
                  className="d-flex justify-content-between align-items-end py-3 border-bottom border-white position-relative"
                >
                  <div>
                    <h3>{job.name}</h3>
                    <span>
                      {translations[job.schedule]}, {job.office}
                    </span>
                  </div>
                  <div>
                    <OutboundLink
                      eventCategory="Special Button"
                      eventAction="Click"
                      eventLabel="Job offer"
                      className="btn btn-light px-6 stretched-link"
                      href={`https://hausgold.jobs.personio.de/job/${job.id}`}
                      target="_blank"
                    >
                      zur Stelle
                    </OutboundLink>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
      <div id="benefits" className="swoosh swoosh-blue swoosh-bottom-right" />

      <ContentBlock block={benefits} title="Das bieten wir Dir" />

      <div id="values" className="swoosh swoosh-blue swoosh-left" />
      <div className="bg-primary py-5">
        <ContentBlock block={values} inverted title="Das ist uns wichtig" />
      </div>
      <div className="swoosh swoosh-blue swoosh-bottom-left" />

      <Container className="mb-7">
        <h3 className="mt-7">Kununu-Bewertung</h3>
        <p>
          Einschätzungen von Bewerbern, Kollegen und Ex-Kollegen zu HAUSGOLD
          findet Ihr außerdem auf dem Arbeitgeber-Bewertungsportal Kununu. Wir
          freuen uns natürlich auch immer über neue Bewertungen!
        </p>
        <Row className="justify-content-md-between justify-content-center">
          <Col xs="7" md="3" className="mt-3 mt-md-0">
            {topCompanyBadge && (
              <GatsbyImage
                image={getImage(topCompanyBadge)}
                alt={topCompanyBadge.title}
              />
            )}
          </Col>
          <Col
            xs="7"
            md="3"
            className="mt-6 mt-md-0 justify-items-center d-flex"
          >
            <img
              width="100%"
              src="https://www.kununu.com/de/partner/KlNbCVZf/self-service-button?button-type=1"
            />
          </Col>
          <Col id="contact" xs="7" md="3" className="mt-6 mt-md-0">
            {openCompanyBadge && (
              <GatsbyImage
                image={getImage(openCompanyBadge)}
                alt={openCompanyBadge.title}
              />
            )}
          </Col>
        </Row>
      </Container>

      <ContentBlock block={contact} />
    </Layout>
  );
};

export default Career;

export const pageQuery = graphql`
  query CareerQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allJobOffer {
      edges {
        node {
          id
          name
          office
        }
      }
    }
    contentfulPage(slug: { eq: "karriere" }) {
      id
      title
      slug
      headline
      metaTitle
      metaDescription
      subTitle
      canonical
      indexable
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
      contentBlocks {
        title
        id
        entries {
          ... on ContentfulList {
            id
            listItems {
              children {
                id
              }
              title
              id
              icon
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            internal {
              type
            }
            title
          }

          ... on ContentfulTextWithImage {
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 500)
              title
            }
            imageAlignment
            template
            title
            internal {
              type
            }
          }
        }
      }
    }
    topCompanyBadge: contentfulAsset(
      contentful_id: { eq: "3zJacCVrKO02yDxQF7XC8H" }
    ) {
      id
      title
      gatsbyImageData(layout: FULL_WIDTH, width: 300)
    }
    openCompanyBadge: contentfulAsset(
      contentful_id: { eq: "aj2q0SF6I2qREhNs648hk" }
    ) {
      id
      title
      gatsbyImageData(layout: FULL_WIDTH, width: 300)
    }
  }
`;
