import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Col, Row } from 'reactstrap';
import { openConsentManager } from '@segment/consent-manager';
import { Button, Flex, Box } from '@hausgold/designsystem';
import Link from './Link';
import Logo from './Logo';
import Trustpilot from './Trustpilot';

/*
 * This is the main Footer version with a curved line.
 */
const Footer = ({ showTrustpilot = true }) => {
  const footerNavigation = {
    partnerprogramme: {
      title: 'Partnerprogramme',
      col: { md: '4' },
    },
    service: {
      title: 'Service',
      col: { md: '4' },
    },
    hausgold: {
      title: 'HAUSGOLD',
      col: { md: '2' },
    },
    recht: {
      title: null,
      col: { md: '2' },
    },
  };

  const year = new Date().getFullYear();

  return (
    <footer className="text-light bg-dark text-center text-md-left pb-3">
      <div className="swoosh swoosh-white d-flex align-items-end">
        <Container className="d-none d-md-block mb-5">
          <Row className="mb-6 mb-md-0 mb-lg-5 d-flex justify-content-center justify-content-md-start">
            <Col md="12">
              <Logo width="150" className="logo" />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="d-flex d-md-none my-6 justify-content-center">
          <Col>
            <Logo width="150" className="logo" />
          </Col>
        </Row>

        <Row className="mb-5">
          <StaticQuery
            query={graphql`
              query ServiceNavigationQuery {
                contentfulNavigation(slug: { eq: "footer" }) {
                  entries {
                    ... on ContentfulCategory {
                      id
                      title
                      slug
                    }
                    ... on ContentfulRedirect {
                      id
                      title
                      slug
                      to
                    }
                    ... on ContentfulPage {
                      id
                      title
                      slug
                    }
                    ... on ContentfulNavigation {
                      id
                      title
                      slug
                      entries {
                        ... on ContentfulRedirect {
                          id
                          title
                          slug
                          to
                        }
                        ... on ContentfulCategory {
                          id
                          title
                          slug
                        }
                        ... on ContentfulPage {
                          id
                          slug
                          title
                          page {
                            slug
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={(data) => (
              <>
                {data.contentfulNavigation.entries.map((entry) => (
                  <Col
                    key={entry.slug}
                    md={footerNavigation[entry.slug].col.md}
                    className="pb-5 pb-md-0"
                  >
                    <strong className="text-muted">
                      {footerNavigation[entry.slug].title || '\u00a0'}
                    </strong>
                    <ul className="mt-4 list-unstyled">
                      {entry.entries.map((subEntry) => {
                        const path = [subEntry.to || subEntry.slug]
                          .filter(Boolean)
                          .join('/');
                        return (
                          <li key={path} className="mt-2 mt-md-0">
                            <Link
                              className="text-light"
                              to={`${
                                path.startsWith('http') ? '' : '/'
                              }${path}`}
                            >
                              {subEntry.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                ))}
              </>
            )}
          />
        </Row>

        <Row className="mb-6">
          <Col xs={{ size: '12', order: '2' }} md={{ size: '6', order: '1' }}>
            <strong className="text-muted">Kostenlose Telefonberatung</strong>
            <p className="display-4 mt-5 mb-6 mb-md-0">
              <a className="text-white" href="tel:+494022616140">
                040 - 22 61 61 40
              </a>
            </p>
          </Col>

          {showTrustpilot && (
            <Col xs={{ size: '12', order: '1' }} md={{ size: '6', order: '2' }}>
              <strong className="text-muted d-block mb-5 mb-md-0">
                Sicherheit garantiert
              </strong>
              <div className="trust-logos d-block d-md-flex justify-content-between align-items-center">
                <div className="d-block d-md-none mb-5" />
                <div
                  className="mx-auto mx-md-0 mt-5 mb-6 mb-md-0"
                  style={{ width: '135px' }}
                >
                  <Trustpilot theme="dark" textAlign="center" />
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div className="d-block mb-6">
          <Button size="sm" variant="unstyled" onClick={openConsentManager}>
            Cookie Einstellungen
          </Button>
        </div>
        <Flex
          as="small"
          id="press-release-dates"
          color="gray.400"
          gap={1}
          textAlign="start"
        >
          <Box fontSize="xs">1</Box>
          <Box>
            FAZ Online, „Viele Kunden könnten zurückkommen“, 17.02.2022, Stand:
            19.10.2023 <br />
            FOCUS online, “Experte erklärt - Wohnimmobilien und Corona: Warum
            Immobilienbesitzer aufatmen können”, 25.05.2023, Stand: 19.10.2023
            <br />
            WELT Online, “Die Luxus-Wette ist verloren – jetzt beginnt das
            Zittern der Spekulanten”, 05.09.2022, Stand: 19.10.2023 <br />
            ZEIT ONLINE, “Unsanierte Immobilien: Das Haus besser saniert
            verkaufen”, 29.06.23, Stand: 19.10.2023
          </Box>
        </Flex>
        <hr />
        <small className="text-muted">
          Copyright {year} - HAUSGOLD | talocasa GmbH | An der Alster 45 | 20099
          Hamburg
        </small>
      </Container>
    </footer>
  );
};

export default Footer;
